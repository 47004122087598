// @flow
import React from 'react'
import cn from 'classnames'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'

import { Store } from 'src/Store'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'src/hoc/withTheme'
import themes, { Theme } from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

export default withTranslation(transDomain)(withTheme(themes)(function Testimonials (props: Props) {
    const refSwiper = React.useRef()
    const refSwiperPagination = React.useRef()

    const { state: { campaign: { testimonials } } } = React.useContext(Store)

    React.useEffect(() => {
        if (refSwiper === null) return

        // eslint-disable-next-line no-new
        new Swiper(refSwiper.current, {
            speed: 750,
            autoplay: 10000,
            mode: 'horizontal',
            loop: true,
            effect: 'slide',
            pagination: {
                el: refSwiperPagination.current,
                type: 'bullets',
                clickable: true
            },
            paginationClickable: true,
            calculateHeight: true
        })
    }, [refSwiper])

    return (
        <>
            <div
                className={styles.testimonials}
                style={{
                    '--author-color': props.theme.authorColor,
                    '--testimonial-color': props.theme.testimonialColor,
                    '--testimonial-font-style': props.theme.testimonialFontStyle,
                    '--testimonial-font-weight': props.theme.testimonialFontWeight,
                    '--testimonial-background-color': props.theme.testimonialBackgroundColor
                }}
            >
                <h3 style={{ display: props.theme.showTitle ? 'block' : 'none' }}>Why do you continue to
                    support {props.theme.title}?</h3>

                <div ref={refSwiper} className={cn(styles.container, 'swiper-container')}>
                    <div className='swiper-wrapper'>
                        {testimonials.map((testimonial, index) => (
                            <div className='swiper-slide' key={index}>
                                <div className={styles.testimonial} style={{ '--testimonial-font-size': props.theme.testimonialFontSize }}>
                                    <blockquote dangerouslySetInnerHTML={{ __html: testimonial.quote }} />
                                    <div className={styles.author}>
                                        <strong className={styles.authorName}>
                                            {Object.values(testimonial.name).join(' ')}
                                        </strong>
                                        {testimonial.position && ' – ' + testimonial.position}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                ref={refSwiperPagination}
                className={styles.pagination}
                style={{
                    '--pagination-bullet-color': props.theme.paginationBulletColor
                }}
            />
        </>
    )
}))
