// @flow
import React from 'react'
import Player from '@vimeo/player'

import extractVimeoId from 'src/util/extractVimeoId'
import { Column, Columns } from 'src/components/Columns'
import Typography from 'src/components/Typography'
import PageContainer from 'src/components/PageContainer'
import Modal from 'src/components/Modal'
import DynamicTextImage from 'src/components/DynamicTextImage'
import Countdown from 'src/components/Countdown'

import { Store } from 'src/Store'

import styles from './styles.module.scss'

import withTheme from 'src/hoc/withTheme'
import supportedThemes, { type Theme } from './themes/__supportedThemes'
import Content from 'src/components/Content'

import { useTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'
import Button from '../Button'

type Props = {
    theme: Theme
}

export default withTheme(supportedThemes)(function Masthead (props: Props) {
    const { state } = React.useContext(Store)
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const { t } = useTranslation(transDomain)

    const videoPlayer = React.useRef()
    const videoRef = React.useCallback(node => {
        try {
            if (node !== null) {
                videoPlayer.current = new Player(node, {
                    id: extractVimeoId(state.campaign.videos.masthead),
                    // muted: true,
                    // background: true,
                    // loop: true,
                    autoplay: true
                })
            }
        } catch (err) {
            console.log(err)
        }
    }, [state.campaign.videos.masthead])

    const playVideo = React.useCallback(() => {
        window.scrollTo(0, 0)
        videoPlayer.current.setCurrentTime(0)
        videoPlayer.current.play().catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <>
            <section className={styles.masthead} style={{ '--color-background': props.theme.background }}>
                <PageContainer>
                    <Columns>
                        <Column size='half'>
                            <div className={styles.message}>
                                <div className={styles.logo} style={{ maxWidth: 380 }}>
                                    <img src={props.theme.logo} alt='' style={{ width: props.theme.logoWidth }} />
                                </div>
                                {props.theme.showCountDown && <>
                                    <span>{t('countDownLabel')}</span>
                                    <div className={styles.countdownContainer}>
                                        <Countdown date={state.campaign.expiryDate} />
                                    </div>
                                </>}
                                <Typography>
                                    {props.theme.showPunchLine && <h1>
                                        {state.lead.fields.firstName && state.lead.fields.firstName !== ''
                                            ? <Content contentKey={'masthead.heading'}>{state.lead.fields.firstName}, Thank you for being a part of their story.</Content>
                                            : <Content contentKey={'masthead.headingEmpty'}>Thank you for being a part of their story</Content>}
                                    </h1>}
                                    {props.theme.showDescription &&
                                    <Content className={styles.messageDescription} style={{ '--color-content-background': props.theme.backgroundContent }} contentKey={'masthead.message'}>
                                        <p>{t('message')}</p>
                                    </Content>}
                                    {props.theme.showMoreLink && state.campaign.website.hasOwnProperty('masthead') && state.campaign.website.masthead.hasOwnProperty('showMoreLink') &&
                                    <Button href={state.campaign.website.masthead.showMoreLink} target='_blank'
                                        style={{ backgroundColor: props.theme.playButtonColor, margin: '15px auto' }}
                                    >Read more</Button>}
                                </Typography>
                            </div>
                        </Column>

                        <Column size='half' noSpacing>
                            <div
                                className={styles.video}
                                onClick={() => {
                                    state.campaign.videos.masthead && setIsModalOpen(true)
                                }}
                            >
                                {props.theme.playButton && <PlayButton
                                    color={props.theme.playButtonColor}
                                    className={styles.playButton}
                                    alt='Play Video'
                                />}
                                <div className={styles.videoThumbnail}>
                                    {typeof props.theme.videoThumbnail === 'string'
                                        ? <img src={props.theme.videoThumbnail} alt='Play video' />
                                        : (
                                            <DynamicTextImage
                                                backgroundImage={props.theme.videoThumbnail.background}
                                                text={(state.lead.fields.firstName && state.lead.fields.firstName !== '')
                                                    ? t(props.theme.videoThumbnail.translationKey, {
                                                        text: state.lead.fields[props.theme.videoThumbnail.fieldsKey]
                                                    })
                                                    : t(props.theme.videoThumbnail.translationKeyConquest, {
                                                        text: state.lead.fields[props.theme.videoThumbnail.fieldsKey]
                                                    })
                                                }
                                                textColor='#3B3B3B'
                                                position={props.theme.videoThumbnail.position}
                                                font={props.theme.videoThumbnail.font}
                                            />
                                        )
                                    }
                                    {props.theme.thumbnailText && (
                                        <div
                                            className={styles.thumbnailText}
                                            dangerouslySetInnerHTML={{ __html: props.theme.thumbnailText }}
                                            style={{ '--font-family-heading': props.theme.fontHeading }}
                                        />
                                    )}
                                </div>

                            </div>
                        </Column>
                    </Columns>
                </PageContainer>
            </section>

            <Modal afterOpen={playVideo} isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)}>
                <div
                    ref={videoRef}
                    id='sidebar-video-modal'
                    className={styles.videoModal}
                />
            </Modal>
        </>
    )
})

const PlayButton = ({ color, ...props }) => (
    <svg
        width='110px'
        height='110px'
        viewBox='0 0 110 110'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink' {...props}
    >
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <circle fillOpacity='0.7' fill={color} cx='55' cy='55' r='55' />
            <circle fill={color} cx='55' cy='55' r='44' />
            <polygon
                fill='#FFFFFF'
                transform='translate(58.000000, 55.000000) rotate(-270.000000) translate(-58.000000, -55.000000)'
                points='58 41 73 69 43 69'
            />
        </g>
    </svg>
)
