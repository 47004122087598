
        import i18n from 'src/i18n';
        import flattenObject from 'src/util/flattenObject';
    
            import ns4640ce4fce25e88176c7e0209ee69b93en_CA from './en-CA.yaml'
            i18n.addResourceBundle('en-CA', '4640ce4fce25e88176c7e0209ee69b93', flattenObject(ns4640ce4fce25e88176c7e0209ee69b93en_CA), true, true);
        
            import ns4640ce4fce25e88176c7e0209ee69b93en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '4640ce4fce25e88176c7e0209ee69b93', flattenObject(ns4640ce4fce25e88176c7e0209ee69b93en_US), true, true);
        
            import ns4640ce4fce25e88176c7e0209ee69b93en from './en.yaml'
            i18n.addResourceBundle('en', '4640ce4fce25e88176c7e0209ee69b93', flattenObject(ns4640ce4fce25e88176c7e0209ee69b93en), true, true);
        
        export default '4640ce4fce25e88176c7e0209ee69b93';
    