// @flow
import React from 'react'
import PageContainer from 'src/components/PageContainer'

import { Currency } from 'src/i18n/NumberFormatter'

import { Store } from 'src/Store'

import styles from './styles.module.scss'
import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'src/hoc/withTheme'
import themes, { type Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

export default withTranslation(transDomain)(withTheme(themes)(function PageFooter (props: Props) {
    const { state } = React.useContext(Store)
    const fields = state.lead.fields

    const currency = React.useMemo(() => Currency(state.currency, state.locale), [state.currency, state.locale])

    return (
        <div
            className={styles.header}
            style={{
                '--background-image': `url(${props.theme.bgImage})`,
                '--font-family-message': props.theme.fontFamilyMessage
            }}
        >
            <PageContainer width={740}>
                <h1>
                    Thank You {state.lead.fields.firstName}, With
                    Your {currency.format(fields.donationAmount)} {fields.donationType !== 'one-time' ? fields.donationType : ''} Donation,
                    We Can Help Make Students’ Dreams
                    Come True.
                </h1>
                <p>Please fill out the payment below to complete your Donation</p>
            </PageContainer>
        </div>
    )
}))
