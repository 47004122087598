// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Currency } from 'src/i18n/NumberFormatter'

import { Store } from 'src/Store'
import * as Form from 'src/components/Form'
import withForm from 'src/components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'
import cn from 'classnames'

import transDomain from './translations/index.translations'
import Content from 'src/components/Content'
import If from 'src/components/If'
import withTheme from 'src/hoc/withTheme'
import themes from './themes/__supportedThemes'
import type { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme,
    values: {
        donationType: ?string,
        donationAmount: ?number,
        donationDesignations: ?Array,

        donationAmountRate: ?number,

        matchingGiftCompany: ?string,
        matchingGiftCompanyName: ?string,

        companyName: ?string,

        firstName: ?string,
        lastName: ?string,
        classYear: ?int,

        addressLine1: ?string,
        addressLine2: ?string,
        city: ?string,
        province: ?string,
        postalCode: ?string,

        email: ?string,
        homePhone: ?string,

        comments: ?string,

        metaInfo: {}
    },
    handleFieldChange: (key: string, value: ?string) => void,
    isSubmitting: boolean
}

export default withForm(true)(withTheme(themes)(function LeadGenForm (props: Props) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    const currencyFormatter = React.useMemo(
        () => Currency(state.currency, state.locale),
        [state.currency, state.locale]
    )

    return (
        <div className={styles.wrapper}>
            <Content
                contentKey={'leadGenForm.description'}
                className={styles.description}
            >
                {t('description', {
                    'clientName': state.campaign.client.name
                })}
            </Content>

            {props.theme.showAlert &&
            <Content
                contentKey={'leadGenForm.alert'}
                className={styles.alert}
                style={{
                    '--alert-background-color': props.theme.alertBackgroundColor,
                    '--alert-font-color': props.theme.alertFontColor
                }}
            >
                {t('alert')}
            </Content>}

            <If condition={state.campaign['payment-gateways'].hasOwnProperty('authorize.net') || state.campaign['payment-gateways'].hasOwnProperty('mock')}
                then={
                    <Form.Row>
                        <Form.Tabs
                            options={['one-time', 'monthly']}
                            defaultValue={props.values.donationType}
                            onChange={value => props.handleFieldChange('donationType', value)}
                            trans={t}
                            labelFormat='field.donationType.option.%name%'
                        />
                    </Form.Row>
                }
            />

            { props.theme.hasDonationAmounts && (<>
                <h3 style={{ marginTop: '2.75em' }}>{t('section.donation.heading')}</h3>

                <Form.Row>
                    <Form.RadioButtons
                        choices={[1, 12, 24]}
                        hasOtherField={false}
                        defaultValue={props.values.donationAmountRate || 1}
                        onChange={value => {
                            if (value === 1) {
                                props.handleFieldChange('donationType', 'one-time', () => {
                                    props.handleFieldChange('donationAmountRate', value)
                                })
                            } else {
                                props.handleFieldChange('donationType', 'monthly', () => {
                                    props.handleFieldChange('donationAmountRate', value)
                                })
                            }
                        }}
                        labelFormat={value =>
                            t(`field.donationAmountRate.choice.${value}.label`, {
                                value: currencyFormatter.format(props.values.donationAmount / value) }
                            )
                        }
                    />
                </Form.Row>
            </>)}

            { props.theme.hasMatchingGift && (<>
                <h3>{t('section.matchingGift.heading')}</h3>

                <Form.Row>
                    <Form.Choices
                        choices={['own-company', 'spouse-company', 'none']}
                        defaultValue={props.values.matchingGiftCompany || 'none'}
                        onChange={value => props.handleFieldChange('matchingGiftCompany', value)}
                        formatLabel={value => t(`field.matchingGiftCompany.choice.${value}.label`)}
                        multiple={false}
                    />
                </Form.Row>

                <Form.Panel
                    hidden={typeof props.values.matchingGiftCompany === 'undefined' || props.values.matchingGiftCompany === 'none'}>
                    <Form.Row>
                        <Form.Field
                            label={t('field.matchingGiftCompany.name.label')}
                            type='text'
                            defaultValue={props.values.matchingGiftCompanyName}
                            onChange={value => props.handleFieldChange('matchingGiftCompanyName', value)}
                            required={typeof props.values.matchingGiftCompany !== 'undefined' && props.values.matchingGiftCompany !== 'none'}
                        />
                    </Form.Row>
                </Form.Panel>
            </>)}

            <h3>{t('section.donorInformation.heading')}</h3>

            <Form.Panel>
                <Form.Row>
                    <Form.Field
                        label={t('field.companyName.label')}
                        type='text'
                        defaultValue={props.values.companyName}
                        onChange={value => props.handleFieldChange('companyName', value)}
                        required={false}
                    />
                </Form.Row>
            </Form.Panel>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={3 / 8}>
                        <Form.Field
                            defaultValue={props.values.firstName || null}
                            onChange={value => props.handleFieldChange('firstName', value)}
                            type='text'
                            label={t('field.firstName.label')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={3 / 8}>
                        <Form.Field
                            defaultValue={props.values.lastName || null}
                            onChange={value => props.handleFieldChange('lastName', value)}
                            type='text'
                            label={t('field.lastName.label')}
                        />
                    </Form.RowColumn>

                    {props.theme.hasClassYear &&
                    <Form.RowColumn size={2 / 8}>
                        <Form.Field
                            defaultValue={props.values.classYear || null}
                            onChange={value => props.handleFieldChange('classYear', parseInt(value))}
                            type='number'
                            label={t('field.classYear.label')}
                            required={false}
                        />
                    </Form.RowColumn>}
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={3 / 4}>
                        <Form.Field
                            defaultValue={props.values.addressLine1 || null}
                            onChange={value => props.handleFieldChange('addressLine1', value)}
                            type='text'
                            label={t('field.addressLine1.label')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1 / 4}>
                        <Form.Field
                            defaultValue={props.values.addressLine2 || null}
                            onChange={value => props.handleFieldChange('addressLine2', value)}
                            type='text'
                            label={t('field.addressLine2.label')}
                            required={false}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={3 / 8}>
                        <Form.Field
                            defaultValue={props.values.city || null}
                            onChange={value => props.handleFieldChange('city', value)}
                            type='text'
                            label={t('field.city.label')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={3 / 8}>
                        <Form.Field
                            defaultValue={props.values.province || null}
                            onChange={value => props.handleFieldChange('province', value)}
                            type='text'
                            label={t('field.province.label')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={2 / 8}>
                        <Form.Field
                            defaultValue={props.values.postalCode || null}
                            onChange={value => props.handleFieldChange('postalCode', value)}
                            type='text'
                            label={t('field.postalCode.label')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            {props.theme.hasContactInformation &&
                (<>
                    <h3>{t('section.contactInformation.heading')}</h3>

                    <Form.Row>
                        <Form.RowColumns>
                            <Form.RowColumn>
                                <Form.Field
                                    defaultValue={props.values.email || null}
                                    onChange={value =>
                                        props.handleFieldChange('email', value)
                                    }
                                    type='email'
                                    label={t('field.email.label')}
                                />
                            </Form.RowColumn>
                            <Form.RowColumn>
                                <Form.Field
                                    defaultValue={props.values.homePhone || null}
                                    onChange={value =>
                                        props.handleFieldChange('homePhone', value)
                                    }
                                    type='phone'
                                    label={t('field.homePhone.label')}
                                />
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Row>
                </>)
            }

            {props.theme.hasAnonymousDonation &&
                (<Form.Row>
                    <div className={styles.anonymousDonationWrapper}>
                        <Form.Checkbox
                            label={t('field.anonymousDonation.label')}
                            defaultChecked={props.values.anonymousDonation}
                            onChange={checked => props.handleFieldChange('anonymousDonation', checked)}
                            required={false}
                        />
                    </div>
                </Form.Row>)
            }

            <h3>{t('section.designation.heading')}</h3>

            <Form.Row>
                {state.campaign.website.donationDesignations.map((donationDesignation, key) => {
                    return (<img
                        key={key}
                        style={{
                            '--paver-background-color': props.theme.colors.primary,
                            '--paver-selected-background-color': props.theme.colors.secondary
                        }}
                        alt={donationDesignation}
                        src={props.theme.pavers[key]}
                        onClick={() => props.handleFieldChange('donationDesignations', [{
                            type: donationDesignation,
                            value: props.values.donationAmount
                        }]) }
                        className={cn(
                            styles.paver,
                            { [styles.active]:
                                    props.values.donationDesignations
                                        ? props.values.donationDesignations[0].type === donationDesignation
                                        : false
                            }
                        )}
                    />)
                })}
            </Form.Row>

            <h3>{t('section.additionalNotes.heading')}</h3>

            <Form.Row>
                <Form.Field
                    label={t('field.comments.label')}
                    help={t('section.additionalNotes.note')}
                >
                    <textarea
                        cols='30'
                        rows='10'
                        onChange={event => props.handleFieldChange('comments', event.target.value)}
                        defaultValue={props.values.comments}
                    />
                </Form.Field>
            </Form.Row>

            <Form.Row>
                <Form.Submit label={t(`submit.${props.theme.submitButtonType}.label`)} isLoading={props.isLoading}/>
            </Form.Row>
        </div>
    )
}))
